import React, { Component } from "react";
import ChronologieData from "./../../data/ChronologieData.json";
import { space } from "./../../Utils/Function";

function Events(index) {
  const event = ChronologieData[index];
  return (
    <div>
      <h3>
        {event.date}
      </h3>
      <p>{space(event.description)}</p>
    </div>
  );
}

function AfficherEvents() {
  let html = [];
  let htmlTitle = [];
  let title = "";

  for (let index = 0; index < ChronologieData.length; index++) {
    if(title !== ChronologieData[index].title){
        title = ChronologieData[index].title;
        html.push(<h2>{title}</h2>);
    }

    html.push(Events(index));
  }
  return html;
}

class Chronologie extends Component {
  render() {
    return (
      <div>
        <h1>Chronologie</h1>
        <p>Les plus grands érudits vous diront qu'il est essentiel de comprendre l'époque à laquelle un oeuvre a été rédigé avant d'en lire et d'en analyser le contenu. Ainsi, en situant chronologiquement les évènements racontés par le texte, vous serez en mesure d'en saisir toutes les subtilités historiques. Bonne lecture.</p>
        <AfficherEvents />
      </div>
    );
  }
}

export default Chronologie;
