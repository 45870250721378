import React from "react";

const Reputation = () => (<div>
  <h1 >Réputation</h1>
    <p>
        La réputation de votre personnage reflète l’impression que celui-ci projette sur le monde qui l’entoure. Au fur et à mesure qu’il progresse et vivra des aventures, sa réputation augmentera. Avec la reconnaissance viennent des avantages. Un héros reconnu pourrait avoir une plus grande facilité à discuter avec des personnages importants et avoir des rabais avec les marchands. Un personnage qui fréquente des cercles plus sombres sera reconnu dans ces cercles. Tout dépendant des actions du personnage, il gagnera de la réputation avec certaines factions et possiblement contre d’autre faction.
    </p>
    <p>
        En-Jeu: Au début de son aventure, votre personnage commence avec une Réputation Générale égale à 0. Un point est ajouté lors de faits extraordinaires et autres causes en jeu. De plus, il est possible de s’associer à une faction ou guilde. Lorsque l’occasion se présentera à votre personnage pour être recruté par une quelconque faction ou guilde, cette réputation affectera la manière dont vous y êtes traiter.. De manière générale, plus vos points sont élevés, plus votre statut augmentera, vous donnant accès à des contacts, des alliés, des ressources et des bonus.
    </p>
    <p>
        Un personnage peut être associé à une guilde et une faction compatibles. Seules les guildes donnent des bonus aux personnages et chaque personnage ne peut choisir qu’une seule guilde. Les bonus donnés représentent l'entraînement et le support offert par la guilde et peut possiblement être bonifié selon le statut du personnage dans la guilde.
    </p>
    <p>
        Notez que les guildes et factions sont des puissantes organisations avec leurs propres agendas et il est possible d’être mêlés à leurs conflits par simple association.
    </p>
</div>)


export default Reputation;