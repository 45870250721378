import React, { Component } from "react";
import { Navbar, NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";

class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, loading: props.loading };
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };
  handleScroll() {
    this.setState({ scroll: window.scrollY });
  }
  componentDidMount() {
    const el = document.querySelector("#hey");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll - 0 > this.state.top
      ? (document.body.style.paddingTop = `65px`)
      : (document.body.style.paddingTop = 0);
  }

  render() {
    return (
      <div
        id="hey"
        className={
          this.state.scroll - 0 > this.state.top ? "sticky" : "top-menu"
        }
      >
        <Navbar inverse collapseOnSelect expand="lg">
          <Navbar.Header>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavDropdown eventKey={1} title="Univers" id="univers-dropdown">
                <MenuItem eventKey={1.1} href="/divinite">
                  Divinités
                </MenuItem>
               {/* <MenuItem eventKey={1.2} href="/demons">
                  Démons
                </MenuItem>*/}
                <MenuItem eventKey={1.3} href="/chronologie">
                  Chronologie
                </MenuItem>
                <MenuItem eventKey={1.4} href="/carte">
                  Carte du monde
                </MenuItem>
                <MenuItem eventKey={1.5} href="/ville">
                  Villes
                </MenuItem>
                <MenuItem eventKey={1.6} href="/factions">
                  Factions
                </MenuItem>
                <MenuItem eventKey={1.7} href="/legendes">
                  Legendes
                </MenuItem>
              </NavDropdown>
              <NavDropdown
                eventKey={2}
                title="Personnage"
                id="personnages-dropdown"
              >
                <MenuItem eventKey={2.1} href="/races">
                  Races
                </MenuItem>
                <MenuItem eventKey={2.2} href="/competences">
                  Compétences
                </MenuItem>
                <MenuItem eventKey={2.3} href="/sorts">
                  Sortilèges
                </MenuItem>
                {/*<MenuItem eventKey={2.3} href="/moralite">
                  Moralité
                </MenuItem>*/}
                <MenuItem eventKey={2.3} href="/premierPersonnage">
                  Premier Personnage
                </MenuItem>
              </NavDropdown>
              {/*<NavItem eventKey={3} href="/triangle">
                Triangle
              </NavItem>
              <NavItem eventKey={4} href="/fiche">
                Fiche
      </NavItem>*/}
              <NavDropdown eventKey={1} title="Règlements" id="reglement-dropdown">
                <MenuItem eventKey={1.1} href="/reglements">
                  Règlement
                </MenuItem>
                <MenuItem eventKey={1.2} href="/creation">
                  Création de personnage
                </MenuItem>
                <MenuItem eventKey={1.4} href="/magie">
                  Magie
                </MenuItem>
                <MenuItem eventKey={1.3} href="/equipement">
                  Équipement
                </MenuItem>
                <MenuItem eventKey={1.4} href="/effets">
                  Effets possible
                </MenuItem>
                <MenuItem eventKey={1.4} href="/alchimie">
                  Alchimie
                </MenuItem>
                <MenuItem eventKey={1.4} href="/langues">
                  Langues
                </MenuItem>
                <MenuItem eventKey={1.4} href="/reputation">
                  Reputation
                </MenuItem>
                <MenuItem eventKey={1.4} href="/guilde">
                  Guildes et Ordres
                </MenuItem>
              </NavDropdown>

              <NavItem eventKey={5} href="/inscription">
                Inscription en Ligne
              </NavItem>
              <NavItem eventKey={6} href="/calendrier">
                Calendrier
              </NavItem>
              <NavItem eventKey={6} href="/informations">
                Informations
              </NavItem>

              {/*<NavItem eventKey={7} href="/photo">
                Photos
              </NavItem>*/}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default TopMenu;
