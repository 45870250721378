import React from "react";
import createur_excel from "../../../src/ressources/document/Createur.pdf";

const PremierPersonnage = () => (
    <div>
        <div class="spellSection">
            <h1 class="title" style={{ textAlign: "center" }}>Votre Premier Personnage</h1>
            <p>La création de votre personnage est l’un des moments les plus décisifs dans votre aventure au Portail des Brumes. Plusieurs étapes sont nécessaires d’effectuer si vous voulez obtenir un résultat et un potentiel maximal pour votre personnage. Cette section du site est dédiée entièrement à vous aider à créer le personnage idéal selon vos désirs.</p>
            <p>Veuillez télécharger le <a class="gras" href={createur_excel} download="Créateur de Personnage">Créateur de personnage</a> qui vous permettra de créer virtuellement votre personnage sur papier et ensuite de le faire imprimer. Avant même le début de la création du personnage, vous devriez prendre connaissance des Règles du Portail des Brumes.</p>
        </div>
        <div class="spellSection">
            <h3 style={{ textAlign: "center" }}>Première étape</h3>
            <p>Le choix de la Race et du nom : Plusieurs races sont disponibles, selon le type de personnage que vous désirez jouer. Cette étape ne doit pas être prise à la légère, après tout, vous serez dans la peau de cette race durant toute sa vie. Chaque race possède des attributs spécifiques que vous devriez étudier. Vous devrez choisir entre un humain, un elfe, un nain, un goliath, un saurien, un homme-dragon ou un lycan. Par la suite, trouvez un prénom et un nom adapté à votre personnage, un elfe se nommant Robert Tremblay n’est pas vraiment crédible.</p>
        </div>
        <div class="spellSection">
            <h3 style={{ textAlign: "center" }}>Deuxième étape</h3>
            <p>Le choix des Compétences. Selon ce que vous voulez voir votre personnage accomplir, vous aurez à choisir différentes compétences et talents. Comme il n’y a aucune classe précise de personnage au Portail des Brumes, vous avez l’opportunité de choisir les talents que vous désirez vraiment utiliser. Prenez le temps de lire et d’analyser toutes les compétences disponibles à fin de modeler votre personnage selon vos désirs.</p>
            <p>Si vous comptez utiliser la magie avec votre personnage, veuillez lire la section Sortilèges. Cette section vous apprendra à maximiser l’efficacité de vos compétences reliées aux pouvoirs magiques et à rentabiliser votre triangle de runes.</p>
        </div>
        <div class="spellSection">
            <h3 style={{ textAlign: "center" }}>Troisième étape</h3>
            <p>Votre histoire (Background). Cette étape de la création de votre personnage n’est pas obligatoire mais fortement recommandée. Profitez de cette belle occasion de faire connaître à l’animation l’histoire de votre personnage. Nous avons tous un vécu précédant aujourd’hui et c’est ce vécu qui intéresse l’animation. Il est beaucoup plus difficile pour nous, de vous dédiez personnellement des quêtes si nous ne connaissons rien de votre personnage. Un coup votre histoire écrite, envoyez-la à l’animation par la section Contacts.</p>
            <p>Voici certains points que vous devriez considérer en écrivant  votre histoire.<br />-Lieu d’origine : Prenez le temps de lire les Légendes et de regarder les Cartes du Portail des Brumes à fin de trouver une ville d’origine à votre personnage. Évitez d’être l’étranger qui arrive simplement du Nord.<br />-Avoir un but : Chaque personnage devrait avoir un but ultime. Que ce soit de devenir le meilleur combattant au monde, le plus puissant des mages, le barde le plus populaire ou de devenir un riche marchand, un but se doit d’être ancré au fond de votre personnage. Ce but peut, pendant l’évolution du personnage, se modifier ou même complètement changer mais fixez-vous une ligne d’arrivée avant même de commencer.<br />-Évitez les stéréotypes : Utilisez votre imagination! Ne soyez pas un autre guerrier venu du Nord suite à l'assassinat de sa famille. Un nain n’est pas obligatoirement en colère contre les elfes sans raison. Tentez de ne pas utiliser des noms déjà connus du grand public comme Aragorn, Altaïr ou Xena.</p>
        </div>
        <div class="spellSection">
            <h3 style={{ textAlign: "center" }}>Quatrième étape</h3>
            <p>Votre costume : Un beau costume ne passe jamais inaperçu et attire toujours le respect chez les joueurs. Prenez un moment pour assembler un costume dans lequel votre personnage prendra vie. Cependant, rappelez-vous qu’au Portail des Brumes, votre personnage mourra un jour ou l’autre, alors ne payez pas un costume mille dollars à moins que ce soit votre réel intention.</p>
        </div>
        <div class="spellSection">
            <h3 style={{ textAlign: "center" }}>Cinquième étape</h3>
            <p>Soyez décorum : Qu’est-ce que le Décorum?<br />Le décorum est l’un des aspects le plus important dans l’univers des Grandeurs-Natures. Il s’agit de tout ce qui concerne le décor et les accessoires médiévaux. Tous les joueurs ainsi que tous les animateurs sont conviés de respecter le décorum. Il est beaucoup plus facile de jouer un rôle et d’y croire si l’environnement est propice à un tel personnage.</p>
            <p>Par exemple :<br />-Simplement dissimuler une glacière sous un tissu ou encore derrière un comptoir.<br />-Avoir un bock en bois ou en métal pour boire au lieu d’une canette ou une bouteille de vitre.<br />-Porter un costume d’aspect médiéval, évitez les jeans et chandails ordinaires.</p>
            <p>Il y a plusieurs façons d’améliorer le décorum et l’animation est toujours plus enchantée d’interagir avec des joueurs qui font l’effort supplémentaire.</p>
        </div>
        <div class="spellSection">
            <h3 style={{ textAlign: "center" }}>Sixième étape</h3>
            <p>N’hésitez jamais de poser vos questions sur <a class="gras" href="https://www.facebook.com/groups/1733210786914575/" target="_blank" rel="noopener noreferrer">Notre page facebook</a> ou encore directement à un animateur. Nous sommes dévoués à offrir une expérience de hors de l’ordinaire et nous prenons ce rôle très au sérieux.</p>
        </div>
    </div>
);
export default PremierPersonnage;