import React from "react";
import Talents from "./../data/TalentData.json";
import Habiletes from "./../data/HabileteData.json";
import Specialisations from "./../data/SpecialisationData.json";

export function space(jsonData) {
  if (jsonData !== null && jsonData !== undefined) {
    return jsonData.split("\n").map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });
  }
  return "";
}

function createHMTLPopups(talent) {
  let htmlPopup = [];

  for (let j = 0; j !== talent.popup.length; ++j) {
    if (talent.popup[0].mot !== "") {

      if(j < 1) {
        htmlPopup.push(<b>Aide-mémoire : </b>);
      }

      htmlPopup.push(
        <a href="#">
          {talent.popup[j].mot}
          <span class="popup">{space(talent.popup[j].description)}</span>
        </a>
      );
      if (j + 1 < talent.popup.length) {
        htmlPopup.push(", ");
      }
    }
  }

  return htmlPopup;
}

function createPrintPopups(talent) {
  let htmlPopup = [];

  for (let j = 0; j !== talent.popup.length; ++j) {
    if (talent.popup[0].mot !== "") {

      if(j < 1){
        htmlPopup.push(<b>Aide-mémoire : </b>);
      }

      htmlPopup.push(
        <div class="spellSection"><p><b>{talent.popup[j].mot} :</b></p><p>{space(talent.popup[j].description)}</p></div>
      );
    }
  }

  return htmlPopup;
}

export function displaySpecialisation(id) {
  let talent = Specialisations[id];
  let htmlPopup = createHMTLPopups(talent);
  let printPopup = createPrintPopups(talent);

  let htmlNiveau = [];
  for (let i = 0; i !== talent.niveau.length; ++i) {
    const niveau = talent.niveau[i];
    let htmlEffets = [];


      htmlNiveau.push(
        <p>
          <b>
            Niveau {i + 1} - {niveau.titre} :
          </b>{" "}
          {niveau.description}
        </p>
      );

     for (let k = 0; k !== niveau.effets.length; ++k) {
       let htmlNotes = [];

       if (niveau.effets[k].note && niveau.effets[k].note.length > 0) {
         let htmlNote = [];
         for (let l = 0; l !== niveau.effets[k].note.length; l++) {
           htmlNote.push(<li>{niveau.effets[k].note[l]}</li>);
         }
         htmlNotes.push(<ul class="a">{htmlNote}</ul>);
       }

       htmlEffets.push(<li>{niveau.effets[k].description}</li>);
       htmlEffets.push(htmlNotes);
     }

      htmlNiveau.push(<ul class="b">{htmlEffets}</ul>);


  }
  return (
    <div class="spellSection">
      <h2 class="title">
        {talent.nom}
      </h2>
      <p class="italique">{talent.prerequis}</p>
      <div>
          <p>{space(talent.description)}</p>
        </div>
      <div>
        <p>{space(talent.Description)}</p>
        {htmlNiveau}
        <p class="invisiblePrint">{htmlPopup}</p>
        <p class="spellSection invisibleSite">{printPopup}</p>
        <p class="italique">{space(talent.détail)}</p>
      </div>
    </div>
  );
}

export function displayHabilete(id) {
  const habilete = Habiletes[id];

  let jeu = [];
  if (habilete.Explication !== "") {
    jeu.push(
      <p>
        <b class="gras">En jeu : </b>
        {space(habilete.Explication)}
      </p>
    );
  }

  return (
    <div class="spellSection">
      <h2 class="title">
        {habilete.nom} - {habilete.type}
      </h2>
      <div>
        <p>{space(habilete.Description)}</p>
        {jeu}
      </div>
    </div>
  );
}

export const WarningLvl5 = "Pour utiliser le niveaux 5 d'une compétences, vous devez  vous le faire enseigner par un PNJ. Vous pouvez dépenser d'avance le point d'expérience pour le niveau 5, mais vous ne pourrez pas l'utiliser avant de l'avoir appris.";

export function renderTalent(talent) {
         let htmlPopup = createHMTLPopups(talent);
         let printPopup = createPrintPopups(talent);

         let htmlNiveau = [];
         for (let i = 0; i !== talent.niveau.length; ++i) {
           const niveau = talent.niveau[i];
           let htmlEffets = [];

            for (let k = 0; k !== niveau.effets.length; ++k) {
              let htmlNotes = [];

              if (niveau.effets[k].note && niveau.effets[k].note.length > 0) {
                let htmlNote = [];
                for (let l = 0; l !== niveau.effets[k].note.length; l++) {
                  htmlNote.push(<li>{niveau.effets[k].note[l]}</li>);
                }
                htmlNotes.push(<ul class="a">{htmlNote}</ul>);
              }

              htmlEffets.push(<li>{niveau.effets[k].description}</li>);
              htmlEffets.push(htmlNotes);
            }

            if (i === 4) {
              htmlEffets.push(<li>{WarningLvl5}</li>);
            }

             htmlNiveau.push(
             <div class="spellSection">
               <p>
                 <b>
                   Niveau {i + 1} - {niveau.titre} :
                 </b>{" "}
                 {niveau.description}
               </p>
               <ul class="b">{htmlEffets}</ul>
             </div>);
         }

         let linkHtml=[];

         if(talent.nom === "Alchimie") {
           linkHtml.push(<a class="invisiblePrint" href='/alchimie'>Voir ici pour les détails</a>);
         }

         let dieu = "";

          if(talent.dieu !== undefined) {
            dieu = "- "+talent.dieu;
          }

         return (
           <div class="spellSection">
             <h2 class="title">
               {talent.nom} - {talent.indice}{dieu}
             </h2>
             {linkHtml}
             <p class="italique">{talent.prerequis}</p>
             <div>
               <p>{space(talent.Description)}</p>
               {htmlNiveau}
               <p class="invisiblePrint">{htmlPopup}</p>
               <p class="invisibleSite">{printPopup}</p>
               <p class="italique">{space(talent.détail)}</p>
             </div>
           </div>
         );
       }

export default Function;
