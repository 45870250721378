import React, { Component } from "react";
import ArmesData from "./../../data/Armes.json";
import ArmuresData from "./../../data/Armures.json";

class Equipement extends Component {
    render() {
        return <div>
            <h1>Liste des armes et des armures</h1>
            <p>Voici les détails importants à connaître à propos de chaque type d’arme. Les prix indiqués sont ceux d’ouverture de personnage. Ensuite, il est toujours possible de vous les procurer durant un scénario soit auprès d’un marchand ou encore d’un forgeron. Aucune vente entre les scénarios ne sera effectuée.</p>
            <p><li>*Toutes les armes sont utilisables avec un indice de force de 1. Cependant, si l’arme demande un indice supérieur à votre force les dégâts effectués avec cette arme seront limités à 1 et ce peu importe vos compétences.</li></p>
            <p><li>*Il est impossible de cumuler la protection de deux armures superposées. Seule l’armure ayant la meilleure protection compte.</li></p>
            <p>Exemple : armure de maille sous une armure de plaque. Uniquement l’armure de plaque de +6 sera utilisée.</p>
            <p>Les prix marqué ne sont valable qu’a la création de personnage.</p>

            <TableArmes />
            <p><li>*Les épées batardes peuvent être considéré comme 1 ou 2 mains mais il faudra avoir le nombre de mains appropriés pour utilisée les compétences associées.</li></p>
            <p><li>*Un arc ne peut tirer en aucun plus de 3 flèches à la fois.</li></p>
            <br />
            <TableArmures />
            <br />
            <table>
                <tr>
                    <th style={{padding: "10px"}}>Boucliers</th>
                    <th style={{padding: "10px"}}>Prix en écu</th>
                    <th style={{padding: "10px"}}>Force</th>
                </tr>
                <tr>
                    <td style={{padding: "10px"}}>Petit bouclier</td>
                    <td style={{padding: "10px"}}>5</td>
                    <td style={{padding: "10px"}}>1</td>
                </tr>
                <tr>
                    <td style={{padding: "10px"}}>Moyen bouclier</td>
                    <td style={{padding: "10px"}}>7</td>
                    <td style={{padding: "10px"}}>2</td>
                </tr>
                <tr>
                    <td style={{padding: "10px"}}>Grand bouclier</td>
                    <td style={{padding: "10px"}}>12</td>
                    <td style={{padding: "10px"}}>3</td>
                </tr>
            </table>
        </div>
    }
}

function Arme(arme) {
    return (
        <tr>
            <td style={{padding: "10px"}}>
                {arme.nom}
            </td>
            <td style={{padding: "10px"}}>
                {arme.prix}
            </td>
            <td style={{padding: "10px"}}>
                {arme.degat}
            </td>
            <td style={{padding: "10px"}}>
                {arme.taille}
            </td>
            <td style={{padding: "10px"}}>
                {arme.force}
            </td>
        </tr>
    );
}

function AfficherArmes(armes) {
    let html = [];
    for (let index = 0; index < armes.length; index++) {
        html.push(Arme(armes[index]));
    }
    return html;
}

function TypeArme(typeArme) {
    return (
        <tr>
            <th style={{padding: "10px"}}>
                {typeArme.type}
            </th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    );
}

function TableArmes() {
    let html = [];
    for (let index = 0; index < ArmesData.length; index++) {
        html.push(TypeArme(ArmesData[index]));
        html.push(AfficherArmes(ArmesData[index].armes));
    }

    return (
        <table>
            <tr>
                <th style={{padding: "10px"}}>Armes</th>
                <th style={{padding: "10px"}}>Prix en écus</th>
                <th style={{padding: "10px"}}>Dégâts</th>
                <th style={{padding: "10px"}}>Grandeur maximale</th>
                <th style={{padding: "10px"}}>Force requise</th>
            </tr>
            {html}
        </table>
    );
}

function Armure(armure) {
    return (
        <tr>
            <td style={{padding: "10px"}}>
                {armure.nom}
            </td>
            <td style={{padding: "10px"}}>
                {armure.prix}
            </td>
            <td style={{padding: "10px"}}>
                {armure.bonus}
            </td>
            <td style={{padding: "10px"}}>
                {armure.force}
            </td>
        </tr>
    );
}

function TableArmures() {
    let html = [];
    for (let index = 0; index < ArmuresData.length; index++) {
        html.push(Armure(ArmuresData[index]));
    }

    return (
        <div>
            <table>
                <tr>
                    <th style={{padding: "10px"}}>Armures</th>
                    <th style={{padding: "10px"}}>Prix en écus</th>
                    <th style={{padding: "10px"}}>Bonus</th>
                    <th style={{padding: "10px"}}>Force requise</th>
                </tr>
                {html}
            </table>
            <p><b>Note:</b>Les armures n'ont pas besoins d'être réparé entre les combats</p>
        </div>
    );
}

export default Equipement;