import React, { Component } from "react";
import img_word from "../../src/../ressources/document/Pdf_icon_file.png";
import reglements_word from "../../../src/ressources/document/livre_regle_final.pdf";
import sorts_word from "../../../src/ressources/document/livre_sorts_final.pdf";
import { Link } from "react-router-dom";

class Reglements extends Component {
  render() {
    return (<div>
      <div>
        <h1>Les règles du jeu</h1>
        <div class="spellSection">
            <h2>Règles de comportement éthique</h2>
            <p>Avant même de discuter de règles de jeu et de création de personnages, voici quelques règles d’éthique.</p>
        </div>
        <div class="spellSection">
            <h3>Le grandeur nature ?</h3>
            <p>Le Portail des Brumes est un jeu de rôle grandeur nature d’aspect médiéval fantastique pour les 18 ans et plus. Plusieurs dizaines de joueurs incarnent des aventuriers avides de quêtes et de gloires. Le Portail des Brumes est donc un jeu avant tout, on y incarne un personnage pour s’amuser. Parfois, le jeu est sérieux, mais ça demeure un divertissement. Tous les joueurs sont responsables de suivre les règles et de veiller à leur propre sécurité ainsi qu’à celle d’autrui.</p>
        </div>
        <div class="spellSection">
            <h3>La Sécurité</h3>
            <p>La sécurité des participants a préséance sur le jeu. Ainsi, ne partez jamais seul en exploration dans les bois. Les fumeurs doivent fumer près d’un feu, pour préserver l’aspect médiéval, mais aussi pour la sécurité. Évidemment, les mégots doivent absolument être disposés à l’intérieur des ronds de feu. La consommation d’alcool est permise, de même que celle de marijuana, de façon modérée. S’il y a des abus, l’organisation se donne le droit de demander aux contrevenants de quitter le terrain pour la durée qui lui semble adéquate. Les substances illégales sont, quant à elles, interdites sur les lieux. L’organisation sera tout sauf clémente avec ceux qui oseraient enfreindre cette règle.</p>
        </div>
        <div class="spellSection">
            <h3>L’honnêteté</h3>
            <p>Le jeu est basé sur l’honnêteté des joueurs. Il est facile de tricher, mais personne n’aura de gloire ou de plaisir à le faire. Il est de la responsabilité de chaque joueur de connaître les règles pour jouer, particulièrement en ce qui a trait aux compétences et sortilèges de son personnage. Par exemple, les incantateurs de sortilèges doivent mémoriser et décrire leurs sortilèges de façon courte et précise.</p>
        </div>
        <div class="spellSection">
            <h3>Le scénario</h3>
            <p>Au Portail des Brumes, un scénario débute le vendredi soir vers 22 h et se termine le dimanche vers 13 h. Le jeu s’y déroule 24 h sur 24 h, la nuit comme le jour donc. Il est par conséquent important de demeurer en tout temps dans votre personnage. Évitez au maximum les arrêts de jeu. Souvenez-vous que jouer son rôle est contagieux, plus vous le ferez, plus les gens qui vous entourent le feront. Jouez-le, en premier, pour vous-même et gardez en tête qu’en grandeur nature, votre public peut très bien être caché dans un buisson.</p>
        </div>
        <div class="spellSection">
            <h3>Le combat</h3>
            <p>Pour votre propre plaisir et celui des autres participants, vous êtes fortement encouragé à jouer le poids réel de vos armes, ainsi que la douleur des coups reçus. Les attaques à la tête et au sexe sont jugées trop dangereuses et donc interdites. Pointer son arme (coups d’estoc) n’est permis qu’avec des armes prévues à cet effet. Si votre personnage est mis hors combat, faites le mort, décorer le champ de bataille. Cependant, pas besoin de vous faire piétiner pour rester dans votre jeu de rôle, tomber près d’un arbre ou ramper hors du chemin afin de rester sécuritaire.</p>
        </div>
        <div class="spellSection">
            <h3>Les quêtes</h3>
            <p>Les personnages au Portail des Brumes accompliront certaines quêtes. Les quêtes peuvent offrir des récompenses ou non. C’est en les accomplissant que vous amasserez des objets, du pouvoir et de la fortune variée. Vous devez en tout temps garder les objets de jeu et les écus sur vous. Cacher un objet nuit au bon déroulement du jeu. Tous les items trouvés en jeu appartenant par conséquent à votre personnage, mais pas à votre personne, doivent être remis à l’organisation à la fin d’un scénario. Si vous trouvez des objets appartenant à d’autres joueurs, apportez-les dès que possible à un animateur. Certains mettent beaucoup de temps et/ou d’argent sur leurs armes, costumes, accessoires, etc. et désireront les récupérer.</p>
        </div>
        <div class="spellSection">
            <h3>La cabane des monstres</h3>
            <p>La cabane des monstres est la bâtisse où l’animation se trouve. Sous la gouverne de ceux que l’on appelle mères des monstres, aucun joueur n’a le droit de passer la frontière de cet endroit. Seul un joueur étant prié de se présenter de l’autre côté du mur délimitant la zone hors-jeu aura le droit d’y accéder.</p>
        </div>
        <div class="spellSection">
            <h3>Le campement</h3>
            <p>Gardez toujours vos campements propres, sécuritaires et décorum. Plus votre campement aura un aspect médiéval, plus l’ambiance en bénéficiera. Garder son campement propre évite les blessures et préserve l’environnement. À la fin du scénario, ramener vos déchets avec vous. Des sacs de poubelle sont disponibles en tout temps à la cabane des monstres.</p>
        </div>
        <div class="spellSection">
            <h3>La règle d’or</h3>
            <p>Ayez du plaisir ! Jouez pour vous amuser et non pas pour gagner.</p>
        </div>
        <div class="spellSection">
            <h2>Les points d’endurance</h2>
            <p>Un personnage incarné par un joueur possède un certain nombre de points d’endurance. Les armures et la magie peuvent contribuer à les augmenter. Un nouveau personnage débute avec un nombre de points d’endurance déterminé par la race choisie. Lorsque l’on se fait frapper par une arme ou un sortilège offensif, on réduit ses points d’endurance. Les points d’endurance peuvent être récupérés en entier en dormant plus de six heures ou en faisant appel à certaines compétences, tels que la magie. Un personnage ne peut pas dépasser un total de trente points d’endurance, il s’agit de la limite d’un corps de mortel. Toutefois, le port d’armure, certains sortilèges, certaines compétences et peut-être même des quêtes peuvent permettre à votre personnage de dépasser cette limite. Aussi, le port d’une armure ajoute aux points d’endurance du personnage. Pour les points d’endurances ajoutés selon le type d’armure, <a href="/equipement"> aller à la page équipement.</a></p>
        </div>
        <div class="spellSection">
            <h2>Le combat</h2>
            <p>Le principe du combat est relativement simple. Chaque arme effectue un dégât prédéterminé par les caractéristiques du personnage. Les sortilèges, quant à eux, ont des dégâts qui peuvent être fixes ou en lien avec une caractéristique. Cependant, certains sorts et compétences peuvent augmenter les dégâts de base. Chaque joueur est responsable de connaître les effets de ses attaques.</p>
        </div>
        <div class="spellSection">
            <h3>L’assaillant</h3>
            <p>Au combat, l’assaillant doit annoncer de vive voix les dégâts effectués par ses armes ou sorts, et ce, à chaque impact. Le dégât infligé par une arme est déterminé par le type d’arme utilisé et la spécialisation que possède le personnage en cette arme. Il est possible qu’une arme inflige un dégât égal à zéro.</p>
        </div>
        <div class="spellSection">
            <h3>Les armes</h3>
            <ul>
              <li> 	Arc : De base, l’arc effectue deux points de dégât aux cibles qu’il touche.</li>
              <li> 	Arbalète : De base, l’arbalète effectue trois points de dégât aux cibles qu’elle touche.</li>
              <li> 	Armes de bois : De base, l’arme de bois, par exemple le bâton ou le gourdin, ne fait pas plus d’un point de dégât.</li>
              <li> 	Armes simples : Les armes simples à une main nécessitent un à l’indice de force pour les manipuler et font un dégât à la base. Les armes simples à deux mains nécessitent deux à l’indice de force et font deux dégâts à la base.</li>
              <li>  Armes lourdes : Les armes lourdes ou surdimensionnées étant plus pesantes, elles demandent deux de force afin d’être manipulées. Elles sont automatiquement considérées à deux mains et font deux dégâts de base.</li>
            </ul>
        </div>
        <div class="spellSection">
            <h3>Les types de dégâts</h3>
            <p>Dans certaines circonstances, une arme peut faire des dégâts particuliers. Il est important de spécifier le ou les types de dégâts s’il y a lieu. Ceux-ci affectent différemment certaines créatures. Exemple : Elan affronte un squelette avec son bâton, il annonce donc ses dégâts de la façon suivante « un contondant ». Les squelettes, particulièrement vulnérables aux dégâts des armes contondantes, réagiront alors différemment. Les principaux types de dégâts sont : magiques, contondants, bénis, maudits, critiques, célestes, de feu et de glace. Bien que ces types de dégâts n’affectent généralement que les créatures, les joueurs sont encouragés à interagir différemment lorsqu’ils sont touchés par des types de dégâts particuliers.</p>
        </div>
        <div class="spellSection">
            <h3>Le défenseur</h3>
            <p>Lorsqu’il se fait attaquer, le défenseur doit, de son côté, déduire de ses points d’endurance les dégâts reçus. Seuls les coups qui ont touché la cible sont comptés. </p>
        </div>
        <div class="spellSection">
            <h3>La sécurité au combat</h3>
            <p>Lors d’une bataille, les coups doivent être donnés des épaules aux pieds. Les coups à la tête ou dans les parties génitales ne seront pas tolérés ; le fautif perdra lui-même les points de dégâts qu’il a portés à son adversaire en guise de pénalité. Également, les coups pointés, avec des armes qui ne sont pas conçues à cet effet, ne sont pas tolérés. Uniquement, les armes approuvées par un organisateur peuvent être utilisées en jeu. On ne peut pas utiliser l’arc pour porter des coups, tout comme le bouclier qui ne peut être utilisé pour charger. Si un animateur juge qu’un coup porté est vraiment dangereux et avait l’intention de blesser sa cible, le joueur sera expulsé. La prudence est de mise.</p>
        </div>
        <div class="spellSection">
            <h3>Mise hors de combat</h3>
            <p>Lorsque l’on tombe inconscient après avoir perdu tous ses points d’endurance, on s’écroule dix minutes au sol. On demeure inanimé tout ce temps, les opportunistes utilisent ce temps pour piller le personnage ou même lui porter le coup de grâce. Le dernier combat ainsi que la minute précédant le combat devra être considéré comme totalement oublié. Une fois ces dix minutes écoulées, le personnage peut se relever en conservant un total de zéro point d’endurance. Tant qu’il ne sera pas guéri, le personnage est inapte autant au combat qu’à la magie ; il ne peut pas utiliser de compétences ; il a peine à marcher et à parler ; c’est un personnage souffrant de ces blessures. Au moindre dégât reçu, le personnage s’écroulera de nouveau au sol, hors combat pour dix minutes. Un personnage meurt, au bout de son sang, s’il demeure à zéro point d’endurance pendant plus de soixante minutes suivant la première perte de conscience.</p>
        </div>
        <div class="spellSection">
            <h3>Le coup de grâce</h3>
            <p>Le personnage meurt également s’il se fait porter le coup de grâce par un autre personnage ou une créature. Un personnage ne possède qu’un seul coup de grâce par scénario. Pour ce faire, il doit simuler trois fois une blessure mortelle sur le corps de sa victime, en disant clairement, à haute voix, et lentement chaque fois qu’il porte le coup de grâce, ainsi que son nom de personnage. Il est également suggéré de dire en jeu à sa victime la raison de sa mise à mort. Exemple : Lyall la prêtresse achève l’assassin de sa sœur en disant clairement trois fois « Pour venger l’assassinat de ma sœur, moi, Lyall, je te porte le coup de grâce, moi, Lyall, je te porte le coup de grâce, moi, Lyall, je te porte le coup de grâce ». Le joueur aura à fournir le nom de son assaillant lors de sa visite à l’animation et ne l’oubliez pas ; l’animation n’est jamais clémente auprès des joueurs qui ont la mémoire courte.</p>
        </div>
        <div class="spellSection">
            <h3>La mort</h3>
            <p>Votre personnage n’est pas immortel, il mourra un jour, c’est inévitable. Il peut mourir dans un combat épique, mais il peut aussi mourir sans aucune raison valable, tué par un cambrioleur, par exemple. Un personnage mort doit rester au sol durant dix minutes. C’est durant ce temps qu’il est possible pour le personnage d’être ressuscité. Si après cette durée, le personnage n’est pas relevé, celui-ci se rend sans délai à la crypte, où son âme sera jugée par la mort. Lors de son déplacement, il doit croiser ses bras en « X » afin de signaler qu’il n’est pas en jeu. Dans le cas où un joueur se retrouverait dans l’enceinte de la crypte, le sort de son personnage appartiendra à la mère des monstres qui a droit de mort en tout temps sur tous les personnages. Nous sommes très sévères et inflexibles à propos de ce règlement. Après la mort de son personnage, le joueur devra en créer un autre. Tous les biens qu’il possédait au moment du coup de grâce sont considérés inutilisables et ne pourront être transmis à personne. Ayez toujours un costume et un personnage de rechange au cas où. Aussi, ne créez pas un personnage possédant une relation avec un autre personnage que vous avez déjà incarné. La variété est fortement encouragée.</p>
        </div>
        <div class="spellSection">
            <h3>La fouille</h3>
            <p>Lors de la conclusion d’un combat, le vainqueur peut s’approprier certains biens du vaincu. Il peut piller les objets visibles, et les objets révélés par la fouille. La fouille peut se faire sur trois zones, soit la ceinture, le haut du corps ou le bas du corps. Chaque zone prend une minute à fouiller. Il est possible d’effectuer une fouille complète des trois zones. La personne effectuant la fouille doit annoncer les zones qu’il désire fouiller avant de commencer, s’il se fait interrompre durant sa fouille, celle-ci est arrêtée et ne peut pas être recommencée. Le joueur fouillé doit dévoiler tous les objets se trouvant dans les zones fouillées. Les objets dévoilés sont susceptibles d’être pillés.</p>
            <p>Le vainqueur peut demander au vaincu, s’il possède des objets magiques parmi ceux révélés. La magie d’un objet est toujours visible. Tous les objets magiques peuvent immédiatement être pillés d’un seul coup. Ensuite, le joueur pourra prendre soit tous les écus, soit tous les lots du vaincu. Une créature ou un personnage terrassé ne peut être pillé qu’une seule fois. Ce qui appartient au joueur et non au personnage ne peut bien sûr pas lui être volé.</p>
        </div>
        <div class="spellSection">
            <h2>Tchak !</h2>
            <p>Le tchak est une exclamation qui indique qu’une courte pause est nécessaire pour informer d’une règle, pour rétablir une mésentente ou pour assurer la sécurité en cas d’accident. Une fois ce cri entendu, tous les joueurs doivent lever une main dans les airs afin d’apporter un support visuel à l’appel et cesser toute action pour écouter ce que la personne veut dire. À moins d’être concerné, vous êtes tenu de rester muet durant un tchak. Sauf lors d’un accident, l’arrêt ne doit pas durer plus de trois secondes et les explications données doivent donc être les plus précises possibles. La personne qui demande un tchak doit inévitablement aviser la fin de celui-ci en disant : « détchak ». Notez qu’il est strictement interdit à un joueur de faire un tchack lors d’une bataille dépassant dix personnes à moins que ce soit une question de sécurité. Dans cette situation, les joueurs utilisant la magie devront se faire comprendre sans stopper la bataille. Il fait partie du devoir du joueur d’utiliser adéquatement ses sortilèges ainsi que ses effets, ainsi que de s’adapter à son environnement.</p>
        </div>
        <p class="invisiblePrint">N'hésitez pas a nous contacter par <a class="gras" href="mailto:portaildesbrumes@gmail.com" target="_blank" rel="noopener noreferrer">Email</a> ou sur notre page <a class="gras" href="https://www.facebook.com/groups/1733210786914575/" target="_blank" rel="noopener noreferrer">Facebook</a>; aucune question n'est mauvaise!</p>
      </div>
      <h1 class="invisiblePrint title">Version offline</h1>

      <div class="invisiblePrint">
        <a href={reglements_word} download="Livre des règles 2.0.pdf"><img src={img_word} style={{ width: "7%", height: "7%" }} alt="" /><p>Livre des règles</p></a>
        <a href={sorts_word} download="Grand grimoire 2.0.pdf"><img src={img_word} style={{ width: "7%", height: "7%" }} alt="" /><p>Livre des sorts</p></a>
      </div>
    </div>
    );
  }
}




export default Reglements;