import AerisImage from "./../ressources/rune/aeris.png";
import AegisImage from "./../ressources/rune/aegis.png";
import AltraImage from "./../ressources/rune/altra.png";
import BoulemImage from "./../ressources/rune/boulem.png";
import CerclumImage from "./../ressources/rune/cerclum.png";
import CeresImage from "./../ressources/rune/ceres.png";
import ChantaImage from "./../ressources/rune/chanta.png";
import CharasImage from "./../ressources/rune/charas.png";
import ConumImage from "./../ressources/rune/conum.png";
import CratiaImage from "./../ressources/rune/cratia.png";
import CreatumImage from "./../ressources/rune/creatum.png";
import CryosImage from "./../ressources/rune/cryos.png";
import DestraImage from "./../ressources/rune/destra.png";
import ElementisImage from "./../ressources/rune/elementis.png";
import EthasImage from "./../ressources/rune/ethas.png";
import GayasImage from "./../ressources/rune/gayas.png";
import GeosImage from "./../ressources/rune/geos.png";
import IrasImage from "./../ressources/rune/iras.png";
import LinimImage from "./../ressources/rune/linim.png";
import LunarImage from "./../ressources/rune/lunar.png";
import NergisImage from "./../ressources/rune/nergis.png";
import PersomImage from "./../ressources/rune/persom.png";
import ProctaImage from "./../ressources/rune/procta.png";
import PyrosImage from "./../ressources/rune/pyros.png";
import SolarImage from "./../ressources/rune/Solar.png";
import TactimImage from "./../ressources/rune/tactim.png";
import MystemImage from "./../ressources/rune/mystem.png";
import VelumImage from "./../ressources/rune/velum.png";
import AlbaImage from "./../ressources/rune/alba.png";
import SanguisImage from "./../ressources/rune/sanguis.png";
import ScioImage from "./../ressources/rune/scio.png";
import PravumImage from "./../ressources/rune/pravum.png";
import ChaosImage from "./../ressources/rune/chaos.png";
import PazzaImage from "./../ressources/rune/pazza.png";

const runes = [
                              {
                                  "name": "Aegis",
                                  "image": AegisImage,
                                  "type": "Celeste",
                              },
                              {
                                  "name": "Ceres",
                                  "image": CeresImage,
                                  "type": "Celeste",
                              },
                              {
                                  "name": "Creatum",
                                  "image": CreatumImage,
                                  "type": "Celeste",
                              },
                              {
                                  "name": "Elementis",
                                  "image": ElementisImage,
                                  "type": "Celeste",
                              },
                              {
                                  "name": "Iras",
                                  "image": IrasImage,
                                  "type": "Celeste",
                              },
                              {
                                  "name": "Lunar",
                                  "image": LunarImage,
                                  "type": "Celeste",
                              },
                              {
                                  "name": "Solar",
                                  "image": SolarImage,
                                  "type": "Celeste",
                              },
                              {
                                  "name": "Mystem",
                                  "image": MystemImage,
                                  "type": "Celeste",
                              },
                              {
                                  "name": "Velum",
                                  "image": VelumImage,
                                  "type": "Celeste",
                              },
                              {
                                  "name": "Aeris",
                                  "image": AerisImage,
                                  "type": "Arcane",
                              },
                              {
                                  "name": "Charas",
                                  "image": CharasImage,
                                  "type": "Divine",
                              },
                              {
                                  "name": "Cryos",
                                  "image": CryosImage,
                                  "type": "Arcane",
                              },
                              {
                                  "name": "Ethas",
                                  "image": EthasImage,
                                  "type": "Divine",
                              },
                              {
                                  "name": "Gayas",
                                  "image": GayasImage,
                                  "type": "Divine",
                              },
                              {
                                  "name": "Geos",
                                  "image": GeosImage,
                                  "type": "Arcane",
                              },
                              {
                                  "name": "Nergis",
                                  "image": NergisImage,
                                  "type": "Arcane",
                              },
                              {
                                  "name": "Pyros",
                                  "image": PyrosImage,
                                  "type": "Arcane",
                              },
                              {
                                  "name": "Boulem",
                                  "image": BoulemImage,
                                  "type": "Manifestation",
                              },
                              {
                                  "name": "Cerclum",
                                  "image": CerclumImage,
                                  "type": "Manifestation",
                              },
                              {
                                  "name": "Conum",
                                  "image": ConumImage,
                                  "type": "Manifestation",
                              },
                              {
                                  "name": "Linim",
                                  "image": LinimImage,
                                  "type": "Manifestation",
                              },
                              {
                                  "name": "Persom",
                                  "image": PersomImage,
                                  "type": "Manifestation",
                              },
                              {
                                  "name": "Tactim",
                                  "image": TactimImage,
                                  "type": "Manifestation",
                              },
                              {
                                  "name": "Altra",
                                  "image": AltraImage,
                                  "type": "Effet",
                              },
                              {
                                  "name": "Chanta",
                                  "image": ChantaImage,
                                  "type": "Effet",
                              },
                              {
                                  "name": "Cratia",
                                  "image": CratiaImage,
                                  "type": "Effet",
                              },
                              {
                                  "name": "Destra",
                                  "image": DestraImage,
                                  "type": "Effet",
                              },
                              {
                                  "name": "Procta",
                                  "image": ProctaImage,
                                  "type": "Effet",
                              }
                  ];

export function runesImageMap(runeName) {
    return runes.find(p => p.name === runeName).image;
}

export function runesArcaneList() {
    return runes.filter(p => p.type === "Arcane").map(p => p.name);
}

export function runesDivineList() {
    return runes.filter(p => p.type === "Divine").map(p => p.name);
}

export function runesCelesteList() {
    return runes.filter(p => p.type === "Celeste").map(p => p.name);
}

export default Function;
