import React, { Component } from "react";
import SortData from "./../../data/SortData.json";
import { runesImageMap, runesArcaneList, runesCelesteList, runesDivineList } from "./../../Utils/RuneImageMap";

class SortPrint extends Component {
  renderSpell(spell) {
      let rune1 = spell.runes[0];
      let rune2 = spell.runes[1];
      let rune3 = spell.runes[2];

    return (<div class="spellSection">
               <div>
                 <h3>{spell.nom}</h3>
               </div>
               <div class="runeImage">
                  <div>
                    <img src={runesImageMap(rune1)} alt="" />
                    <p class="center">{rune1}</p>
                  </div>
                  <div>
                    <img src={runesImageMap(rune2)} alt="" />
                    <p class="center">{rune2}</p>
                  </div>
                  <div>
                    <img  src={runesImageMap(rune3)} alt="" />
                    <p class="center">{rune3}</p>
                  </div>
              </div>

           <div>
               <div>
                 <p>
                   <b>Puissance: </b>
                   {spell.puissance}
                 </p>
                 </div>
                 <div>
                 <p>
                   <b>Portée: </b>
                   {spell.portee}
                 </p>
               </div>
               <div>
                 <p >
                   <b>Cibles: </b>
                   {spell.region}
                 </p>
                 <p >
                   <b>Durée: </b>
                   {spell.duree}
                 </p>
               </div>
           </div>
          <div class="spell-description">
             <p>
               <b>Description: </b>
               {spell.description}
             </p>
              <p >
                <b>Note: </b>
                {spell.note}
              </p>
          </div>
    </div>);
  }

  renderSpells(spellList){

    let spellListRendered = [];
    for(let i=0; i !== spellList.length; ++i){
        spellListRendered.push(this.renderSpell(spellList[i]));
    }

    return spellListRendered;
  }

  renderSpellType(){
    let spellList = [];

    spellList.push(
        <h1>Magie Arcane</h1>
    );
    spellList.push(this.processSpellType(runesArcaneList()))

    spellList.push(
        <h1>Magie Divine</h1>
    );
    spellList.push(this.processSpellType(runesDivineList()))


    spellList.push(
        <h1>Magie Céleste</h1>
    );
    spellList.push(this.processSpellType(runesCelesteList()))

    return spellList;
  }

  processSpellType(runeList){
    let spellList = [];

    for(let j=0; j !== runeList.length; j++){
        let spells = SortData.filter(p => runeList[j] === p.runes[0])
        let spellsRendered = this.renderSpells(spells);

        if(j === 0){
            spellList.push(
                <div>
                    <h2 class="title">{runeList[j]}</h2>
                    {spellsRendered}
                </div>
            );
        } else {
            spellList.push(
                <div>
                    <h2 class="sautPage">{runeList[j]}</h2>
                    {spellsRendered}
                </div>
            );
        }
    }

    return spellList;
  }

  render() {
    return (
      <div>
        {this.renderSpellType()}
      </div>
    );
  }
}

export default SortPrint;