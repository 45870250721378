import React, { Component } from "react";
import { Link } from "react-router-dom";
import { runesMap } from "./../../Utils/Runes.js";
import TriangleExemple from "../../ressources/TriangleExemple.png"

class Magie extends Component {

    render() {
        const runeMap = runesMap();

        let stillHasRunes = true;
        let nbRune = -1;
        let html = [];

        while (stillHasRunes) {
            let innerHtml = [];
            let nbUndefined = 0;
            for (let i = 0; i !== runeMap.length; ++i) {
                if (nbRune === -1) {
                    innerHtml.push(<th>{runeMap[i].type}</th>)
                } else {

                    if (runeMap[i].runes[nbRune] === undefined) {
                        nbUndefined++;
                        innerHtml.push(<td></td>);
                    } else {
                        innerHtml.push(<td>  <img
                            src={(runeMap[i].runes[nbRune].image)}
                            alt={runeMap[i].runes[nbRune].name}
                            class="center"
                            style={{
                                borderCollapse: "collapse",
                                paddingLeft: "10px"
                            }}
                        /><figcaption>{runeMap[i].runes[nbRune].name}</figcaption></td>)
                    }
                    if (nbUndefined === runeMap.length) {
                        stillHasRunes = false;
                    }
                }
            }
            html.push(<tr>
                {innerHtml}
            </tr>)


            nbRune++;
        }

        return (<div>
            <h1>Sortilèges, magie et runes</h1>
            <p>La magie est un pouvoir que chacun possède en lui. Pour la plupart ce pouvoir est dormant. Toutefois, certains personnages ont développé l’art de le manipuler. Les érudits ont réussi à catégoriser les composantes, les manifestations et les effets de la magie en les illustrant par des runes. Les runes sont en fait des aptitudes mentales que les adeptes de la magie maîtrisent pour puiser l’énergie et la manipuler à ses fins. Il existe également d’autres formes magiques comme la magie céleste qui ne peut être octroyée que par l’intermédiaire d’un dieu.
            Le Grand Grimoire vous offre la liste de ces sortilèges. Pour l’instant, voici les runes classées dans leur catégorie respective.
            </p>

            <table>
                {html}
            </table>
            <div class="spellSection">
                <h2>La rune de composante</h2>
                <p>La rune de composante est la source de puissance qui alimente tous sortilèges. C’est l’essence même de toute forme de magie. Par exemple, tous les sortilèges impliquant le froid puiseront leur puissance de la rune d’eau. Ces mêmes sortilèges ne pourront être lancés que par un personnage ayant acquis la rune de composante d’eau. Certains personnages débutent avec la capacité d’exploiter une à trois runes de composantes, soit jusqu’à deux runes attribuées par sa race et une acquise à la naissance. D’autres races n’ont aucun talent pour la magie. La rune de naissance sera attribuée lors de la création du personnage.</p>
            </div>
            <div class="spellSection">
                <h2>La rune de manifestation</h2>
                <p>La rune de manifestation représente l’art de manipuler l’énergie magique. C’est ce talent qui permettra au mage de cibler et de manifester le sortilège. Par exemple, pour accomplir un sortilège de boule de feu, la rune de composante du feu fournira la puissance et la rune de manifestation de sphère, sculptera cette puissance pour qu’elle se manifeste sous cette forme. Le nombre de cibles affectées par le sortilège est déterminé par cette même rune. Un personnage peut acquérir ce type de rune en investissant un de ses points de compétences dans la maîtrise de la magie ou lors d’une progression runique.</p>
                <p><i>*À moins d’un sortilège de toucher ou personnel, les cibles doivent se trouver devant l’incantateur. Ainsi, les cibles victimes d’un cône de glace devront faire face à l’incantateur et non, être sur ses côtés.</i></p>
            </div>
            <div class="spellSection">
                <h2>La rune d’effet</h2>
                <p>Cette dernière et non la moindre représente le type d’effet qu’un sortilège accomplira. Par exemple, un sortilège de sommeil ensorcellera sa victime par l’effet de la rune d’enchantement. Un personnage peut également acquérir ce type de rune en investissant un de ses points de compétence dans la maîtrise de la magie ou lors d’une progression runique.</p>
            </div>
            <div class="spellSection">
                <h2>La magie Céleste</h2>
                <p>Cette rune peut être incantée que par les dévots d’un dieu. Les runes divines sont les suivantes, Solar, Lunar, Créatum, Mystem, Iras, Aegis, Ceres, Velum et Elementis. La magie céleste peut être invoquée en portant tout type d’armure de maille ou de cuir.</p>
            </div>
            <div class="spellSection">
                <h2>La Magie Divine</h2>
                <p>Il est important de noter que les runes de composantes ; éther, nature et chaire sont, des runes qui sont attribuées par les dieux eux-mêmes. On appelle les sortilèges utilisant ces runes ; les sortilèges Divin. La magie divine peut être invoquée en portant tout type d’armure de maille ou plus léger.</p>
            </div>
            <div class="spellSection">
                <h2>La Magie Arcane</h2>
                <p>Les runes ; Feu, Eau, Air, Terre et Énergie, sont des runes arcannes et les sortilèges nécessitant l’une de ces runes sont des sortilèges de nature arcane.</p>
            </div>
            <div class="spellSection">
                <h2>Les sortilèges</h2>
                <p>De cette façon, tous sortilèges magiques se composent toujours d’un minimum de trois runes soit d’au moins une dans chaque catégorie. Par exemple, le sortilège foudre se compose des runes suivantes : Énergie - Ligne -	Dégâts</p>
                <p><i>*Le sortilège de foudre consiste en une ligne d’énergie infligeant des dégâts à sa cible.</i></p>
            </div>
            <div class="spellSection">
                <h2>Le tableau des runes</h2>
                <p>Chaque rune que possède un personnage doit être placée dans le tableau des runes. Ce tableau se compose de 25 cases triangulaires agencées pour former un seul grand triangle. Le joueur devra placer ses runes dans ces cases de façon à former ses sortilèges.</p>

                <div>
                    <img
                        src={TriangleExemple}
                        alt="img no found"
                        style={{
                            height: "50%",
                            width: "50%",
                            paddingLeft: "10px"
                        }}
                    />
                </div>
            </div>
            <div class="spellSection">
                <h2>Combiner les runes en sortilèges</h2>
                <p>Un sortilège est formé lorsqu’une rune de composante sur le tableau touche à une de manifestation, qui elle touche à une rune d’effet. Cette combinaison consécutive « composante - manifestation - effet » constitue un sortilège. Donc, les sortilèges qu’un personnage peut faire sont entièrement exprimés par la position de ses runes dans son tableau.</p>
                <p>Chaque rune ainsi placée peut servir à plusieurs combinaisons. Le choix de l’emplacement de chaque rune est donc important. Il n’est pas rare pour un adepte de la magie d’avoir plusieurs fois la même rune dans son tableau des runes. En plaçant les runes de façon adéquate, il est donc possible d’avoir quelques fois le même sortilège.</p>
                <p><i>* Le nom des sortilèges pour chaque combinaison se trouve dans l’annexe : <Link to="/sorts">le Grand Grimoire</Link> </i></p>
            </div>
            <div class="spellSection">
                <h2>Le nombre de sortilèges</h2>
                <p>Le nombre de sortilèges qu’un adepte de la magie peut lancer est égal au nombre de sortilèges exprimés par le tableau. En réalité, chaque sortilège exprimé par le tableau ne peut être lancé qu’une fois. Un sortilège ainsi utilisé n’est plus accessible au personnage, il ne sera récupéré qu’après une période de méditation ou après une nuit de sommeil de six heures. On ne peut débuter la méditation d’un sort dont l’effet est toujours en vigueur, il faudra attendre la fin de sa durée.</p>
                <p>Exemple : Ralf maîtrise six runes et les a disposées de façon identique au tableau ci-haut. Ainsi, dans un combat matinal contre des darkons, Ralf utilise son sortilège de « Foudre ». Il ne lui reste plus que les sortilèges de « Boule de feu » et de « Jet de flamme ». Toutefois, plus tard en après-midi, Ralf profite d’une demi-heure de repos pour méditer son sortilège de « Foudre », récupérant ainsi le sortilège pour une prochaine utilisation.</p>
            </div>
            <div class="spellSection">
                <h2>Méditation des Sortilèges </h2>
                <p>Les personnages doivent méditer leurs sortilèges afin de regagner ceux-ci après leurs utilisations. Afin de regagner un sortilège, le mage ou le prêtre doit méditer durant trente minutes. Cependant l’habileté Méditation et certains objets magiques ou artéfacts peuvent accélérer le processus et le nombre de sorts gagnés lors de celle-ci. Le seul autre moyen de regagner ses sortilèges utilisés est de dormir pour une durée minimale de six heures.</p>
                <p>Lors d’une méditation, vous ne pouvez utiliser aucune compétence ou sortilège. De plus, vous ne pouvez pas combattre. Il est permis de discuter à propos de la magie ou de spiritualité, mais en restant en place.</p>
            </div>
            <div class="spellSection">
                <h2>Incanter un sortilège</h2>
                <h3>Les sortilèges doivent être lancés selon ces étapes</h3>
                <ol>
                    <li>Pose</li>
                    <li>Incantation</li>
                    <li>Nom du sortilège</li>
                    <li>Type</li>
                    <li>Description précise</li>
                </ol>
            </div>
            <div class="spellSection">
                <h3>Pose</h3>
                <p>L’incantateur doit se positionner avant d’incanter un sortilège. Il doit toujours avoir les deux pieds immobiles au sol et tenir une main libre dans les airs.</p>
            </div>
            <div class="spellSection">
                <h3>Incantation du sortilège</h3>
                <p>Les trois premiers mots d’une incantation sont déterminés selon les runes du sortilège. Par exemple, Eugène qui possède les runes du sortilège Jet de flammes incanté un mot pour chacune d’elle : Feu (Pyros), Ligne (Linim) et Destruction (Destra). L’incantateur doit prendre le temps de bien prononcer chaque rune séparément (près d’une seconde par rune).</p>
            </div>
            <div class="spellSection">
                <h3>Nom du sortilège</h3>
                <p>Dois mentionner clairement le nom du sortilège tel qu’inscrit dans le grimoire. Par exemple : Foudre ! </p>
            </div>
            <div class="spellSection">
                <h3>Type du sortilège</h3>
                <p>Certains sortilèges ont un type, d’autre pas. Les types les plus communs sont les sortilèges de charme, de malédiction, de terre, d’air, d’énergie, de feu et de glace. Il existe des compétences et sortilèges permettant à un personnage d’être immunisé contre un type en particulier.</p>
            </div>
            <div class="spellSection">
                <h3>Description précise du sortilège</h3>
                <p>Chaque sortilège a une description exhaustive. Les détails pertinents de ceux-ci feront agir les cibles affectées conséquemment. La description du sortilège doit se faire rapidement, soit en moins de trois secondes. Les incantateurs connaissant bien leurs sortilèges décriront toujours ceux-ci d’une même façon, gagnant ainsi temps et clarté. Certains sortilèges ont une note à l’incantateur. Cette note n’a pas à être spécifiée lorsque le sortilège est lancé.</p>
            </div>
            <div class="spellSection">
                <h3>Incanter en armure </h3>
                <p>Les sortilèges de magie arcane (utilisant les runes ; feu, glace, air, terre et énergie) ne peuvent être lancés en armure. La complexité qui les caractérise ne permet pas au magicien d’exécuter adéquatement le rituel s’il est vêtu d’une quelconque protection (armure, brassard, jambière ou casque) qui n’est pas uniquement d’origine magique. Donc, une armure de cuir magique ne permet pas à l’incantateur de lancer ses sortilèges. Une robe magique par contre lui permet, car la protection de celle-ci est uniquement d’origine magique.</p>
                <p>Les sortilèges de magie divine (utilisant les runes éther, nature et chaire) sont moins capricieux. Un personnage peut porter une protection de mailles et toujours être apte à lancer ces sortilèges.</p>
                <p><li>*Maîtrise runique 3 permets d’augmenter de la capacité d’incanter en armure.</li></p>
            </div>
            <div class="spellSection">
                <h3>Combinaison de sortilèges </h3>
                <p>Si deux sortilèges dont les effets sont contradictoires affectent une même cible, le dernier lancé aura préséance sur celui d’avant. Si deux sortilèges ayant le même effet affectent une même cible, le plus récent des deux aura préséance sur l’autre.</p>
            </div>
            <div class="spellSection">
                <h3>Rompre un sortilège </h3>
                <p>L’incantateur d’un sortilège peut en tout temps rompre son sortilège avant l’expiration de sa durée. </p>
            </div>
            <div class="spellSection">
                <h3>La puissance des sortilèges et du personnage</h3>
                <p>Chaque être vivant possède des pouvoirs magiques, mais ce ne sont pas tous qui le développe. Certaines races et individus sont particulièrement doués dans l’art de la magie et de la prêtrise. Même si la magie est compliquée et méconnue, les sages ont pu remarquer quelques règles qui semblent régir de la magie de toutes sortes.</p>
                <p>En premier lieu, tous les sortilèges ont la même puissance, cependant, plus le nombre de cibles est grand, plus la puissance d’un sortilège est divisée. Ce qui détermine la source première d’un sortilège est naturellement la rune de composante. Par contre, c’est la rune de manifestation qui détermine le nombre de cibles du sortilège. Finalement, c’est la rune d’effet qui détermine la forme que le sortilège va prendre.</p>
                <p>La puissance de base d’un sortilège est fixe. Cependant, ce qui augmente et affecte réellement la puissance d’un sortilège est la puissance du mage. <b>Notez que les indices magiques d'un personnage ne peuvent pas dépasser quinze (15).</b></p>
            </div>
            <div class="spellSection">
                <h3>La Puissance du Lanceur de sorts</h3>
                <p>Il existe plusieurs moyens disponibles pour augmenter la puissance d’un lanceur de sorts. </p>
                <p>Le moyen le plus courant pour le lanceur de sorts d’augmenter sa puissance est au travers de ses Progressions Runiques. Le joueur pourra améliorer soit sa puissance (+1 dégât), sa persistance (+1 minute sur les sorts offensifs +5 minutes pour les sorts passifs) ou sa portée (+1 mètre)</p>
                <p>Le personnage peut également augmenter ceux-ci grâce aux Talents ; Maîtrise runique, Ferveur céleste et Positionnement magique. Les autres façons pour un lanceur de sorts d’augmenter sa puissance sont par des objets magiques, par un bonus de Guilde ou d’Ordre, par une compétence, par un sortilège et/ou par un bonus obtenu d’un Animateur au travers d’une quête ou par son Jeu de rôle exemplaire.</p>
                <p>Peut importe le moyen, un personnage ne peut pas avoir plus qu'un total de 15 par indice</p>
            </div>
        </div>);
    }
}


export default Magie;