import React from "react";

const Calendrier = () => (
    <div style={{ textAlign: "center" }}>
        <h1>Calendrier de la saison 2024</h1>
        <p>Voici les dates de nos évènements :</p>
        <h3>Scénarios</h3>
        <p>1er: 14-15-16 Juin</p>
        <p>2e : 26-27-28 Juillet</p>
        <p>3e : 30-31-1 Sept</p>
        <p>3e : 4-5-6 Oct</p>
        <p></p>
        <p>On vous invite également à joindre notre groupe facebook pour toute mise à jour et vous tenir au courant : <br/><a class="gras" href="https://www.facebook.com/groups/1733210786914575/" target="_blank" rel="noopener noreferrer">Groupe Facebook - Portail des Brumes</a></p>
    </div>
);
export default Calendrier;
