import React from "react";
import { Table } from "react-bootstrap";
import Alizé_Zéphir from "./../../ressources/legendes/Alizé_Zéphir.pdf";
import Après_la_traversée from "./../../ressources/legendes/Après_la_traversée.pdf";
import Avellana_Ombrefeuille from "./../../ressources/legendes/Avellana_Ombrefeuille.pdf";
import La_bataille_des_falaises from "./../../ressources/legendes/La_bataille_des_falaises.pdf";
import La_grande_migration from "./../../ressources/legendes/La_grande_migration.pdf";
import La_guerre_aberrante from "./../../ressources/legendes/La_guerre_aberrante.pdf";
import Les_premiers_contacts from "./../../ressources/legendes/Les_premiers_contacts.pdf";
import Xyandhar_Sornyus  from "./../../ressources/legendes/Xyandhar_Sornyus .pdf";
import pdf_logo from "../../../src/ressources/document/Pdf_icon_file.png";


const Legendes = () => (
    <div>
      <h1>Légendes</h1>
      <div>
          <h2>La grande migration (Cycle 0 à 5)</h2>
          <div style={{ display: "flex" }}>
                <a style={{ padding: "5px" }} target="_blank" href={Après_la_traversée}><img src={pdf_logo}  alt="" /><p>Après la traversé</p></a>
                <a style={{ padding: "5px" }} target="_blank" href={Les_premiers_contacts}><img src={pdf_logo}  alt="" /><p>Les Premiers Contacts</p></a>
          </div>
      </div>
        <div>
            <h2>La guerre aberrante (Cycle 5 à 42)</h2>
              <div style={{ display: "flex" }}>
                    <a style={{ padding: "5px" }} target="_blank" href={La_guerre_aberrante}><img src={pdf_logo}  alt="" /><p>La guerre aberrante</p></a>
                    <a style={{ padding: "5px" }} target="_blank" href={Avellana_Ombrefeuille}><img src={pdf_logo}  alt="" /><p>Avellana Ombrefeuille</p></a>
                    <a style={{ padding: "5px" }} target="_blank" href={La_bataille_des_falaises}><img src={pdf_logo}  alt="" /><p>La bataille des falaises</p></a>
                    <a style={{ padding: "5px" }} target="_blank" href={Alizé_Zéphir}><img src={pdf_logo}  alt="" /><p>Alizé Zéphir</p></a>
                    <a style={{ padding: "5px" }} target="_blank" href={Xyandhar_Sornyus}><img src={pdf_logo}  alt="" /><p>Xyandhar Sornyus</p></a>
              </div>
        </div>
        <div>
            <h2>L'Exploration de l'Archipel (Cycle 42 à 121)</h2>
            <ul>
                <li>À venir</li>
            </ul>
        </div>
        <div>
            <h2>La Grande Expansion (Cycle 121 à maintenant)</h2>
            <ul>
                <li>À venir</li>
            </ul>
        </div>
    </div>
);
export default Legendes;
