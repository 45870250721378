import React, { Component } from "react";
import DiviniteData from "./../../data/DiviniteData.json";
import { space } from "./../../Utils/Function";

function AfficherArchange(archange) {
  return (
    <div>
      <h4><b>{archange.nom}</b></h4>
      <p>{archange.description}</p>
    </div>
  );
}

function AfficherMajeur(divinite) {
  let htmlArchange = [];
  if (divinite.archanges !== null && divinite.archanges.length !== 0) {
    htmlArchange.push(<h4><b>Ses Archanges :</b></h4>);
    for (let i = 0; i < divinite.archanges.length; i++) {
      htmlArchange.push(AfficherArchange(divinite.archanges[i]));
    }
  }

  return (
    <div class="spellSection">
      <h3><b>{divinite.nom}</b></h3>
      <p><b>Alignement : </b>{divinite.alignement}</p>
      <p><b>Description : </b>{space(divinite.description)}</p>
      <p><b>Symbole : </b>{divinite.symbole}</p>
      <p><b>Armes de prédilection : </b>{divinite.arme}</p>
      {htmlArchange}
    </div>
  );
}

function AfficherMineur(divinite) {
  return (
    <div class="spellSection">
      <h3><b>{divinite.nom}</b></h3>
      <p><b>Description : </b>{space(divinite.description)}</p>
      <p><b>Symbole : </b>{divinite.symbole}</p>
    </div>
  );
}

function AfficherEntite(divinite) {
  return (
    <div class="spellSection">
      <h2>{divinite.nom}</h2>
      <p>{divinite.description}</p>
    </div>
  );
}

function AfficherDivinites(index) {
  let htmlMajeur = [];
  let htmlMineur = [];
  let htmlEntite = [];

  for (let index = 0; index < DiviniteData.length; index++) {
    const divinite = DiviniteData[index];
    htmlMineur.push(AfficherMineur(divinite));
  }

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Les Divinités</h1>
      <h2>Les croyances religieuses</h2>
      <p>
        Les nouveaux arrivants du Portail des Brumes sur l’Archipel n’y ont rencontré que les goliaths. N’étant pas un peuple très religieux, leur connaissance générale des différentes divinités est très limitée. Malgré leur mémoire troublée par leur traversée, les colons se souviennent partiellement des dieux et déesses régnant sur leur monde d’origine. Certains ont essayé de les prier et de les contacter, sans succès. Ils se rappellent d’êtres tout-puissants, omnipotents et désintéressés, étant soit bons ou mauvais. Les Goliaths soutiennent que la réalité est toute autre. Les divinités d’Awa ne seraient ni immortelles, ni organisées en quelconque ensemble, ni même créateurs de l’univers. Ils seraient tous moralement gris, tous dotés de lumière et de noirceur. Mais ces dires proviennent d’un seul peuple ; peu religieux qui plus est. Les nouveaux arrivants ont donc rapidement fait preuve de davantage d’introspection et ont commencé à approfondir eux-mêmes leurs connaissances sur les dieux et déesses de leur monde d’accueil. Il est fort à parier que les colons du Portail des Brumes en découvriront plus au fur et à mesure qu’ils exploreront cette terre étrangère…</p>
      <div>{htmlMineur}</div>
    </div>
  );
}

class Divinite extends Component {
  render() {
    return (
      <AfficherDivinites />
    );
  }
}

export default Divinite;
