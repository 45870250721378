import React, { Component } from "react";
import Talents from "./../../data/TalentData.json";
import Habiletes from "./../../data/HabileteData.json";
import Specialisations from "./../../data/SpecialisationData.json";
import { Route, Link } from "react-router-dom";
import { PanelGroup, Panel, Button } from "react-bootstrap";
import { WarningLvl5, space, displaySpecialisation, displayHabilete, renderTalent } from "./../../Utils/Function";

function Competence({ match }) {
  if (match.params.type === "Talents") {
    return displayTalent({ match });
  } else if (match.params.type === "Habiletes") {
    return displayHabilete(match.params.id);
  } else if(match.params.type === "Technique"){
    return displaySpecialisation(match.params.id);
  }

  return <p>Mauvais url</p>;
}

function displayTalent({ match }) {
  const id = match.params.id;
  const talent = Talents[id];

  if (talent.nom === "Dévotion") {
    return (
      <div>
        <div class="w3-col m12">{MenuDevot({ match }, "Dévotion")}</div>
        <Route path={`${match.path}/:dieu`} component={displayDevotion} />
        <Route
          exact
          path={match.path}
          render={() => renderTalent(Talents[3])}
        />
      </div>
    );
  }

  return renderTalent(talent);
}

function displayDevotion({ match }) {
  let talent = Talents.filter(function (value, index, array) {
    return value.dieu === match.params.dieu;
  })[0];
  return renderTalent(talent);
}

function MenuDevot({ match }, type) {
  let html = [];
  let talentDevot = Talents.filter(function (value, index, array) {
    return value.dieu !== undefined;
  });

  for (let index = 0; index < talentDevot.length; index++) {
    const talent = talentDevot[index];
    if (talent.nom === type) {
      html.push(
        <Link to={`${match.url}/${talent.dieu}`}>
          <Button
            style={{ marginRight: "5px", width: "95px", marginBottom: "10px" }}
          >
            <b class="gras">{talent.dieu}</b>
          </Button>
        </Link>
      );
    }
  }

  return html;
}

function MenuTalents({ match }) {
  let html = [];

  for (let index = 0; index < Talents.length; index++) {
    const talent = Talents[index];
    if (talent.dieu === undefined) {
      html.push(
        <Link to={`${match.url}/Talents/${index}`}>
          <Button style={{ width: "100%" }}>
            --{talent.indice}--
            <br />
            <b class="gras">{talent.nom}</b>
          </Button>
        </Link>
      );
    }
  }

  return html;
}

function MenuHabiletes({ match, type }) {
  let html = [];
  for (let index = 0; index < Habiletes.length; index++) {
    const habilete = Habiletes[index];
    if (type === habilete.type) {
      html.push(
        <Link to={`${match.url}/Habiletes/${index}`}>
          <Button style={{ width: "100%" }}> {habilete.nom}</Button>
        </Link>
      );
    }
  }
  return html;
}

function MenuSpecialisation({ match, type }) {
  let html = [];
  for (let index = 0; index < Specialisations.length; index++) {
    const specialisation = Specialisations[index];
      html.push(
        <Link to={`${match.url}/Technique/${index}`}>
          <Button style={{ width: "100%" }}> {specialisation.nom}</Button>
        </Link>
      );
  }
  return html;
}

class Competences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      match: props.match,
      menuActive: ""
    };
  }

  toggleMenu(e, nom) {
    if (this.state.menuActive === nom) {
      this.setState({ menuActive: "" });
    } else {
      this.setState({ menuActive: nom });
    }
  }

  render() {
    return (
      <div>
        <div id="test" class="w3-col m3" style={{ paddingTop: "20px" }}>
          <PanelGroup accordion id="accordion">
            <Panel eventKey="1">
              <Panel.Heading>
                <Panel.Title toggle componentClass="h3">
                  <Button
                    style={{
                      width: "100%",
                      backgroundImage: `url(${"../src/ressources/background/pancarte_2.png"})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover"
                    }}
                  >
                    Les Talents
                  </Button>
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <div class="menu">
                  <MenuTalents match={this.state.match} />
                </div>
              </Panel.Body>
            </Panel>
             <Panel eventKey="2">
              <Panel.Heading>
                <Panel.Title toggle componentClass="h3">
                  <Button style={{ width: "100%" }}>
                    Les Techniques
                  </Button>
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <div class="menu">
                  <MenuSpecialisation match={this.state.match} />
                </div>
              </Panel.Body>
            </Panel>
            <Panel eventKey="3">
              <Panel.Heading>
                <Panel.Title toggle componentClass="h3">
                  <Button style={{ width: "100%" }}>
                    Les Habiletés Générales
                  </Button>
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <div class="menu">
                  <MenuHabiletes match={this.state.match} type="Générale" />
                </div>
              </Panel.Body>
            </Panel>
            <Panel eventKey="4">
              <Panel.Heading>
                <Panel.Title toggle componentClass="h3">
                  <Button style={{ width: "100%" }}>Les Métiers</Button>
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <div class="menu">
                  <MenuHabiletes match={this.state.match} type="Métier" />
                </div>
              </Panel.Body>
            </Panel>
            <Panel eventKey="5">
              <Panel.Heading>
                <Panel.Title toggle componentClass="h3">
                  <Button style={{ width: "100%" }}>
                    Les Habiletés de races
                  </Button>
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <div class="menu">
                  <MenuHabiletes match={this.state.match} type="Race" />
                </div>
              </Panel.Body>
            </Panel>
          </PanelGroup>
        </div>

        <div class="w3-col m9" style={{ paddingLeft: "25px" }}>
          <Route
            path={`${this.state.match.path}/:type/:id`}
            component={Competence}
          />
          <Route
            exact
            path={this.state.match.path}
            render={() => (
              <div>
                <h1>Les Compétences</h1>
                <p>
                  Les compétences sont les capacités d’un personnage. Elles sont
                  séparées en deux catégories : les habiletés et les talents.
                  Les habiletés sont les compétences accessibles à tous. Elles
                  ne demandent pas d’indice particulier Les talents sont des
                  compétences influencées par les indices. Ils nécessitent
                  d’avoir certaines habiletés ou un niveau particulier dans un
                  indice pour être acquis.
                  <br/>
                  <br/>
                  <b>Note:</b> {WarningLvl5}
                </p>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

export default Competences;
