import React from "react";

const Alchimie = () => (<div>
  <h1>Alchimie</h1>
  <h2>La préparation d’élixirs</h2>
    <p>
    La connaissance des herbes et de l’alchimie permet de fabriquer des élixirs, des poisons et des potions. Les effets de certaines potions dépassent, et de loin, le simple remède pour soulager un mal de dos. Lorsqu’un personnage travaille sur une préparation d’élixir il doit :
    </p>
        <ul>
            <li>Posséder dans un livre la recette de la préparation</li>
            <li>Trouver ou acheter les ingrédients nécessaires à la fabrication de la potion</li>
            <li>Passer un laps de temps à la fabrication dans un laboratoire</li>
            <li>S’assurer de mettre le colorant alimentaire de la bonne couleur pour identifier le type de potion. Seule chose observable par les autres personnages.</li>
        </ul>
    <p>
        Un laboratoire doit être un endroit à l’abri du vent et du soleil, où l’alchimiste viendra y installer ses grimoires et ses instruments.
    </p>
    <p>
    Concocter consiste à inclure certains ingrédients à de l’eau ou à d’autres substances, puis le liquide sera, ensuite, chauffé à la chandelle pour la durée de la préparation. Une préparation donne une seule dose ; la quantité du liquide et des ingrédients n’a aucun impact sur la dose, qui sera toujours unique. Notez que si au cours de la préparation, l’alchimiste doit quitter son laboratoire, la préparation en cours sera perdue. Les potions ne durent pas plus d’un scénario, sauf sous certaines exceptions dont l’animation vous mettra au courant. Elles ne peuvent donc pas être conservé entre les scénarios.
    </p>

    <h2>Les ingrédients</h2>
    <p>Les ingrédients de bases doivent être trouvés (cueillis) ou achetés auprès de certains marchands. Certains ingrédients ne peuvent être trouvés uniquement sur le corps de créatures et d’autres sont d’une grande rareté et peuvent avoir des effets d’une puissance incroyable.
       </p>
    <p>*Seul un Alchimiste peut cueillir des ingrédients et les utiliser, puisqu’il est le seul à connaître les secrets de la cueillette sans détruire ceux-ci.</p>
    <p>*Un carnet d’alchimie sera remis au joueur au début du scénario. Dans ce carnet, vous trouverez les images représentant les ingrédients que vous pourrez trouver dans la nature. Si vous ne trouvez pas les ingrédients exacts, prenez les ingrédients les plus près en apparence que vous pourrez trouver.
           </p>
  <h2>L’effet d’une potion</h2>
  <p>À l’exception des potions de guérison et poisons, un personnage ne peut pas être sous l’effet de plus de deux potions à la fois. S’il boit une potion supplémentaire, l’effet prend le dessus sur la première et la remplace. </p>

  <h2>L’apparence de la potion </h2>
  <p>Les alchimistes ajoutent du colorant pendant la fabrication de la potion pour simuler les ingrédients et la texture, ainsi que pour identifier sa catégorie. Il est donc possible pour tous de savoir visuellement de quel type de potion il s’agit, cependant celui qui l’a préparé est le seul qui sait exactement l’effet qu’elle aura. Si un personnage trouve une fiole pleine, il devra se renseigner auprès des alchimistes pour en découvrir l’effet. Les effets de la potion doivent être identifiés sur la potion. Il est interdit d’écrire une mauvaise identification, mais il est possible pour un alchimiste de mentir sur les propriétés de sa potion. Évidemment, le joueur qui acquiert la potion devra être avertit hors-jeu de ses effets véritables.</p>

  <h2>Les ingrédients spéciaux </h2>
  <p>Ces ingrédients sont rares et connus seulement de quelques alchimistes d’expérience. Il est possible en les ajoutant à une potion d’en modifier ou d’en amplifier les effets. Les ingrédients spéciaux sont quelques fois disponibles chez les marchands nomades, chez les coureurs des bois ou chez les maîtres alchimistes. Il est possible également de tenter des expériences avec des ingrédients trouvés en jeu, mais il n’arrive que très rarement qu’un de ces ingrédients soit réellement efficace ou que l’alchimiste découvre la bonne recette. Il est même possible que la potion explose et détruise l’équipement de l’alchimiste ou que la concoction soit tout simplement gâchée. Celui qui risque ce genre d’expérimentation doit contacter un animateur pour savoir l’effet qu’aura son élixir. Notez qu’il sera nécessaire d’ingurgiter la potion pour découvrir l’effet (après avoir contacté la personne-ressource), l’alchimiste ne peut pas déduire ces effets par la seule apparence de la fiole, mais en aura quand même une vague idée selon la couleur qu’elle prendra. </p>

  <div class="spellSection">
  <h2>Note</h2>
  <p>Il est fortement recommandé de ne pas utiliser des fioles de vitres, vous pouvez facilement en trouver en plastiques dans certains magasins comme le Dollorama. Ne buvez pas le contenu d’une fiole, à moins que l’animation le dicte autrement.</p>
    </div>
</div>)


export default Alchimie;