import React from "react";
import Groupe from "./../ressources/Photo/groupe.png";
import Demon from "./../ressources/Photo/demon.jpg";
import Ifrit from "./../ressources/Photo/ifrit.jpg";
const Introduction = () => (
  <div>
    <div class="w3-row-padding w3-padding-16">
      <h1>Le Grand Départ!</h1>
      <div class="w3-col m6">
      <p>Bienvenue au Portail des Brumes</p>
      <p>Il y a un peu plus de cent cycles, un portail de pierre jusqu’alors inactif s’est ouvert sur l’Archipel. En sont
      sorties quelques centaines de personnes dont la mémoire collective semble avoir été effacée. Ils ne savent
      qu’une chose. Ils sont les survivants d’une catastrophe et ont fui vers un nouveau monde. Les Goliaths, seuls
      habitants de l’Archipel avant cette migration forcée, les ont accueillis avec froideur au début, tout a changé
      rapidement quand les aberrations ont commencé à apparaitre en nombre.</p>
      <p>Les aberrations sont des créatures violentes et extrêmement dangereuses créées par un incident dans les flux
      magiques. Après l’ouverture du portail, elles se sont mises à attaquer de plus en plus fréquemment et en
      quantité sans cesse grandissante; détruisant les campements des nouveaux arrivants et les villes et villages
      goliaths sans distinction. Les deux parties ont donc décidé de former une alliance afin de combattre ces
      monstres. Ce qu’on appelle aujourd’hui la Guerre Aberrante se termina après presque trente ans de batailles.
      Après ces événements, l’alliance continua et les deux peuples évoluèrent chacun de leur côté de l’Archipel.
      Le Conseil des Héros fut fondé pour gouverner les nouveaux arrivants. La populace s’est donc divisée en
      quatre factions. Les Pionniers : élitistes explorateurs et conquérants; les M.A.G.E.S : académistes avides de
      connaissances; le Consortium : gardiens du commerce et de ses partisans et les Protecteurs d’Awa :
      défenseurs de l’ordre naturel.</p>
      <p>La population de l’Archipel s’est ainsi développée et étendue jusqu’au jour où la place et les ressources se
      sont mises à manquer. Il a fallu alors construire des navires à brumes, des engins à vapeur alimentés par des
      pierres magiques et capables de traverser les tempêtes permanentes qui entourent l’Archipel. Grâce à eux,
      l’exploration a pu commencer.</p>
      <p>La première colonie fut établie par la mission du capitaine Delan, aujourd’hui gouverneur de l’endroit, et se
      nomme Élysia. C’est ici que débute votre aventure. Plus exactement dans le campement avancé de cette
      ville née il y a à peine quelques cycles et qui doit maintenant faire face à un monde immense qui est en
      pleine découverte. Évidemment, dans les derniers cycles, d’autres villes sont apparues autour d’Élysia,
      financées et fondées par les différentes factions. Ces mouvements indépendants ont créé une tension
      grandissante dans la politique de l’Archipel rendant la neutralité d’Élysia aussi précieuse que précaire.</p>
      <p>Combattrez-vous pour garder Élysia des dangers? Plongerez-vous dans les joutes diplomatiques?
      Explorerez-vous les secrets d’un univers en pleine expansion ou bien ceux qui se cachent dans le monde
      que vous croyez connaître? Il y a tellement de façons de venir écrire l’histoire. Qu’elle sera la vôtre?</p>

      </div>
      <div class="w3-col m6">
              <img src={Groupe} style={{ width: "100%", height: "100%",  }} alt="" />

            <p> <br />
                Nous vous invitons à nous envoyer votre idée de personnage{" "}
                <a href="mailto:portaildesbrumes@gmail.com" target="_blank" rel="noopener noreferrer">ici</a>. <br />
                Pour toute question, venez vous inscrire sur{" "}
                <a href="https://www.facebook.com/groups/1733210786914575/" target="_blank" rel="noopener noreferrer">
                  notre page facebook!
                </a>
                <br />
                <br />
                <b>L’équipe d’animation du Portail des Brumes 2022</b>
              </p>

      </div>
    </div>
  </div>
);
export default Introduction;
