import React from "react";

const Effets = () => (<div>
    <h1 >Les maladies et les poisons qui peuvent affectés votre personnage en jeu</h1>
    <h2>Les maladies</h2>
    <p>Lors de leurs aventures, les personnages du Portail des Brumes peuvent contracter certaines maladies. Il existe plusieurs maladies divisées en deux types ; les simples et les complexes. Les maladies peuvent être contractées de plusieurs façons ; par morsure, par ingestion, par toucher ou par contact sanguin.</p>

    <p>Si vos indices sont diminué et que vous avez un talent sans avoir le niveau d'indice correspondant, le niveau de ce talent ne pourra plus être utilisé jusqu'à la gérison de la maladie. Par exemple : Eugène a son indice de raisonnement à 3, il a aussi son talent d'alchimie à 3, s'il attrape la maladie syndrome du mage, il ne pourra plus fabriqué de potion de niveau 3 jusqu'à la guérison de la maladie.</p>

    <h3>Les maladies simples :</h3>
    <p>Ces maladies sont temporaires, elles durent un maximum d’un scénario. Cependant, elles ont des effets néfastes immédiats sur la santé de votre personnage. Elles sont plus faciles à guérir que les maladies complexes. Voici la liste des maladies simples les plus répandu:</p>

    <table>
        <tr>
            <th style={{padding: "10px"}}>Maladies</th>
            <th style={{padding: "10px"}}>Effets</th>
        </tr>
        <tr>
            <td style={{padding: "10px"}}>Fièvre noire (douleurs musculaires)</td>
            <td style={{padding: "10px"}}>Le personnage perd 1 point de Force</td>
        </tr>
        <tr>
            <td style={{padding: "10px"}}>Empoisonnement alimentaire (manque d’énergie)</td>
            <td style={{padding: "10px"}}>Le personnage perd 1 point de Vigueur</td>
        </tr>
        <tr>
            <td style={{padding: "10px"}}>Maladie du dragon (plaques rouges sur le corps)</td>
            <td style={{padding: "10px"}}>Le personnage perd 1 point de Prestance</td>
        </tr>
        <tr>
            <td style={{padding: "10px"}}>Syndrome de l’archer (légers tremblements)</td>
            <td style={{padding: "10px"}}>Le personnage perd 1 point de Dextérité</td>
        </tr>
        <tr>
            <td style={{padding: "10px"}}>Syndrome du mage (migraines)</td>
            <td style={{padding: "10px"}}>Le personnage perd 1 point de Raisonnement</td>
        </tr>
        <tr>
            <td style={{padding: "10px"}}>Maladie du pèlerin (hallucinations légères)</td>
            <td style={{padding: "10px"}}>Le personnage perd 1 point d’Introspection</td>
        </tr>
        <tr>
            <td style={{padding: "10px"}}>Fièvre rouge (fatigue corporelle)</td>
            <td style={{padding: "10px"}}>Le personnage perd 2 p-e</td>
        </tr>
        <tr>
            <td style={{padding: "10px"}}>Rage</td>
            <td style={{padding: "10px"}}>Le personnage tombe en frénésie totale pendant 30 minutes. Il attaquera n’importe qui dans son champ de vision avec ses armes de mêlée jusqu’à ce qu’il soit guéri ou que la durée de l’infection (30 mins) soit terminée. </td>
        </tr>
    </table>

    <h3>Les maladies complexes : </h3>
    <p>Ces maladies sont permanentes, leurs effets durent tant qu’elles ne sont pas guéries. Leurs effets sont extrêmement néfastes sur la santé de votre personnage. Elles sont plus difficiles à guérir que les maladies simples et peuvent entraîner la mort de votre personnage. Ces maladies perdurent jusqu’à ce qu’elles soient guéries.</p>

    <h2>Les poisons</h2>
    <p>Durant ses aventures, votre personnage pourrait être victime de poison ou encore en infliger les conséquences à ses adversaires. Il existe différentes façons de se procurer du poison ; par achat, par habileté de race ou encore en le fabriquant de vos mains. Chaque poison possède des caractéristiques qui leur sont propres. Leur point en commun est qu’ils prennent tous 20 secondes à agir à 100 %. À partir du moment où la cible est affectée par un poison, nous demandons à ce que le jeu de rôle soit aussi affecté jusqu’à ce que l’effet soit total. Les seules exceptions à la règle des 20 secondes sont les poisons blessants qui possèdent un effet immédiat. </p>
    <p>Le poison mortel : Ce poison très rare est plus spécial que les autres. Comme le poison terrassant, il prend 20 secondes pour plonger sa victime dans un coma. Par la suite, le poison commence à détruire le corps de l’intérieur. Si aucune action n’est prise, la cible en meurt en 30 minutes, suite à l’exposition de celui-ci.</p>

</div>)


export default Effets;
