import React, { Component } from "react";
import FactionsData from "./../../data/FactionsData.json";

function AfficherFaction(index) {
  const faction = FactionsData[index];

  let sousFactions = AfficherSousFactions(faction.sousFaction);

  return (
    <div>
      <h2>{faction.titre}</h2>
      <p><b>Description : </b>{faction.description}</p>
      {sousFactions}
    </div>
  );
}

function AfficherSousFactions(sousFactions){
    let html = [];

   for (let index = 0; index < sousFactions.length; index++) {
     html.push(AfficherSousFaction(sousFactions[index]));
   }

   return html;
}

function AfficherSousFaction(sousFaction) {
  return (
    <div>
      <h3>{sousFaction.nom}</h3>
      <p><b>Leader : </b>{sousFaction.leader}</p>
      <p><b>Bonus : </b>{sousFaction.bonus}</p>
      <p><b>Description : </b>{sousFaction.description}</p>
    </div>
  );
}

function AfficherFactions() {
  let html = [];
  for (let index = 0; index < FactionsData.length; index++) {
    html.push(AfficherFaction(index));
  }
  return html;
}

class Factions extends Component {
  render() {
    return (
      <div>
        <h1>Factions</h1>
        <AfficherFactions />
      </div>
    );
  }
}

export default Factions;
