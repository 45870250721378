import React from "react";

const Langues = () => (<div>
    <h1>Les langues</h1>
    <p>Il existe des multitudes de langues dans l’univers du Portail des Brumes. Certaines sont plus rares et d’autres sont même complètement oubliées de la quasi-totalité du monde. La totalité des langues est classée dans trois catégories (les langues Courantes, les Langues Complexes et les Langues Mortes). Un texte codé ne peut être décodé par nul autre qu’un Érudit, à moins qu’il s’agisse d’un parchemin ayant le mot DÉFI inscrit en bas à droite.
Voici le registre des langues connues et leurs catégories :
</p>
    <h2>Langues Courantes</h2>
    <ul>
        <li>Le Goliath</li>
        <li>L’Arcanique</li>
        <li>Le Sylvanique</li>
        <li>Le Nécromant</li>
        <li>L'Atlante</li>
        <li>Le Sametas</li>
    </ul>

    <h2>Langues Complexes</h2>
    <ul>
        <li>L’Abérrant</li>
        <li>Le Célestial</li>
        <li>L'Ezcareth</li>
    </ul>
</div>)


export default Langues;