import React, { Component } from "react";
import Racesdata from "./../../data/racesdata.json";
import { space, displaySpecialisation, displayHabilete, renderTalent } from "./../../Utils/Function";
import PremierPersonnage from "../Personnage/PremierPersonnage";
import Talents from "./../../data/TalentData.json";
import Habiletes from "./../../data/HabileteData.json";
import Specialisations from "./../../data/SpecialisationData.json";

import Reglements from "../reglements/Reglements";
import Equipement from "../reglements/Equipement";
import Reputation from "../reglements/Reputation";
import Langues from "../reglements/Langues";
import Magie from "../reglements/Magie";
import Guilde from "../reglements/Guilde";
import Effets from "../reglements/Effets";
import Alchimie from "../reglements/Alchimie";
import Divinite from "../Univers/Divinite";


class SortPrint extends Component {

  displayRace(id) {
     const races = Racesdata[id];

     return (
       <div class="spellSection">
         <h2 class="title">{races.nom}</h2>
         <div class="">
             <p>{space(races.description)}</p>
         </div>
         <table>
             <tr>
                 <th style={{textAlign:"left", width:"750px", padding: "10px"}}>
                    <h3>Indices</h3>
                 </th>
                 <th style={{textAlign:"left", width:"750px", padding: "10px"}}>
                   <h3>Caractéristiques</h3>
                  </th>
             </tr>
           <tr>
             <td style={{ textAlign:"left", width:"750px", padding: "10px"}}>
               <p>Force : {races.force}</p>
               <p>Dextérité : {races.dexterite}</p>
               <p>Vigueur : {races.vigueur}</p>
               <p>Raisonnement : {races.raisonnement}</p>
               <p>Introspection : {races.introspection}</p>
               <p>Prestance : {races.prestance}</p>
             </td>
             <td style={{ textAlign:"left", width:"750px", padding: "10px"}}>
               <p>Points d'endurance : {races.vie}</p>
               <p>Nombres de compétences : {races.nbcompetences}</p>
               <p>Rune d'élément : {races.rune}</p>
               <p>Avantages : {space(races.avantages)}</p>
               <p>Espérance de vie : {races.esperance}</p>
             </td>
           </tr>
         </table>

       </div>
     );
   }

  renderRace(){
    let htmlRace = [];

    htmlRace.push(<h1>Les races jouables</h1>);
    htmlRace.push(            <p>
                                Ces races ont bien certainement leurs caractéristiques propres.
                                Certaines font d'agiles archers, d'autres de puissants guerriers.
                                Pour cela, une race débute avec un nombre fixe de points dans les
                                cinq indices, un nombre de points d'endurance, un nombre de
                                compétences de départ ainsi que des avantages et des contraintes.
                                Également, la race choisie confère une rune d'élément
                                prédéterminée et ainsi qu'une rune de naissance au choix à la
                                création du personnage. Le tableau suivant démontre les
                                particularités des différentes races.
                              </p>);

    for(let i = 0; i !== Racesdata.length; ++i){
        htmlRace.push(this.displayRace(i));
    }

    return htmlRace;
  }

    renderTalents(){
      let htmlTalent = [];
      htmlTalent.push(<h1>Les Talents</h1>);

      for(let i = 0; i !== Talents.length; ++i){
           htmlTalent.push(renderTalent(Talents[i]));
      }

      return htmlTalent;
    }

     renderSpecialisation(){
       let htmlSpecialisation = [];
       htmlSpecialisation.push(<h1>Les Techniques</h1>);

       for(let i = 0; i !== Specialisations.length; ++i){
           htmlSpecialisation.push(displaySpecialisation(i));
       }

       return htmlSpecialisation;
     }

     renderHabilite(){
       let htmlhabilite = [];
       htmlhabilite.push(<h1>Les Habiletés</h1>);

       for(let i = 0; i !== Habiletes.length; ++i){
            htmlhabilite.push(displayHabilete(i))
       }

       return htmlhabilite;
     }

  renderCompetences(){
    let htmlCompetence = [];
    htmlCompetence.push(<div>{this.renderTalents()}</div>);
    htmlCompetence.push(<div>{this.renderSpecialisation()}</div>);
    htmlCompetence.push(<div>{this.renderHabilite()}</div>);
    return htmlCompetence;
  }

  render() {
    return (
      <div>
        <div>
            <PremierPersonnage />
        </div>
        <div>
            {  this.renderRace() }
        </div>
        <div>
            {this.renderCompetences()}
        </div>
        <div>
            <Reglements />
        </div>
        <div>
            <Equipement />
        </div>
        <div>
            <Magie />
        </div>
        <div>
            <Effets />
        </div>
        <div>
            <Alchimie />
        </div>
        <div>
            <Langues />
        </div>
        <div>
            <Reputation />
            <Guilde />
        </div>
        <div>
            <Divinite />
        </div>
        <div>

        </div>
      </div>
    );
  }
}

export default SortPrint;