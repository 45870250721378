import React from "react";
import { Table } from "react-bootstrap";

const Informations = () => (
  <div>
    <div>
      <h1>Tarifs</h1>
      <div>
        <Table>
          <tr>
            <th />
            <th>Prix/Scénario</th>
            <th>Passe Saison (4 Scénarios)</th>
          </tr>
          <tr>
            <th>Joueur/Joueuse</th>
            <td>50$</td>
            <td>160$</td>
          </tr>
        </Table>
      </div>

      <h3>Nouveau joueur :</h3>
      <ul>
        <li>
          Si vous êtes un nouveau joueur, vous profitez d'un <b>rabais de 10$</b> sur 
          l'achat de votre premier scénario. (appliquable aussi sur la passe saison)
        </li>
        <li>
          Lorsque vous êtes accompagné d’un nouveau joueur, vous pouvez demander de lancer
          un D20 de bonus qui se reflètera en jeu. Tous les chiffres sont gagnants!
        </li>
      </ul>
    </div>
    <div>
      <h1>Pour nous joindre</h1>
      <p>Pour être au courant des dernières nouvelles, suivez notre page Facebook : <a class="gras" href="https://www.facebook.com/groups/1733210786914575/" target="_blank" rel="noopener noreferrer">Portail des Brumes</a></p>
      <p>Écrivez-nous par Courriel pour toutes informations ou questions : <a class="gras" href="mailto:portaildesbrumes@gmail.com" target="_blank" rel="noopener noreferrer">portaildesbrumes@gmail.com</a></p>
    </div>
    <div>
      <h1>Lieu</h1>
      <p>
        Le terrain du Portail des Brumes est situé au : 852, Rte 222, Maricourt,
        J0E 2L2
      </p>
      <div style={{ textAlign: "center" }}>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56331.574602376466!2d-72.3235445527314!3d45.54227711056961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb7d6feaafecec3%3A0xd5d44b888be5b635!2s852+QC-222%2C+Valcourt%2C+QC+J0E+2L0!5e0!3m2!1sfr!2sca!4v1551641538885"
          width="600"
          height="450"
          frameborder="0"
          style={{ border: "0", margin: "auto" }}
          allowfullscreen
        />
      </div>
    </div>
  </div>
);
export default Informations;
