import React, { Component } from "react";
import VilleData from "./../../data/VilleData.json";

const zonePadding = 1.7;
const offsetContinent = {
  tl: {x:0., y:0.},
  br: {x:1., y:1.}
}

const offsetArchipel = {
  tl: {x:0.645, y:-0.025},
  br: {x:0.998, y:1.007}
}

function getCoord(ville, offset) {
  const offsetX = (ville.x - offset.tl.x) / (offset.br.x - offset.tl.x);
  const offsetY = (ville.y - offset.tl.y) / (offset.br.y - offset.tl.y);
  const padding = zonePadding / (offset.br.x - offset.tl.x)
  return {
    left: Math.round(offsetX*100-padding/2)+"%",
    top: Math.round(offsetY*100-padding/2)+"%",
    width: padding+"%", 
    height: padding+"%", 
    display: "block",
    position: "absolute"
  };
}

function getPath(index) {
  return `/ville/${index}`;
}

function ZoneVille({offset}) {
  let html = [];

  for (let index = 0; index < VilleData.length; index++) {
    const ville = VilleData[index];
    if(ville.x === undefined || ville.y === undefined) continue;
    if(offset.tl.x>ville.x || offset.tl.y>ville.y) continue;
    if(offset.br.x<ville.x || offset.br.y<ville.y) continue;
    html.push(
      <a style={getCoord(ville, offset)} href={getPath(index)} alt={ville.nom}/>
    );
  }

  return html;
}



class Carte extends Component {
  render() {
    return (
      <div>
        <h1>La carte du monde</h1>
        <div style={{position:"relative", top:0, left:0}}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={require(`./../../ressources/carte/carte_du_monde_2023.png`)}
            alt="map"
          />
          <ZoneVille offset={offsetContinent}/>
        </div>
        <h1>L'Archipel</h1>
        <div style={{position:"relative", top:0, left:0}}>
          <img
            style={{ width: "100%", height: "100%"}}
            src={require(`./../../ressources/carte/archipel.png`)}
            alt="map"
          />
          <ZoneVille offset={offsetArchipel}/>
        </div>
      </div>
    );
  }
}

export default Carte;
